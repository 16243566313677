import { ScrapexBreadcrumbs } from '@common/components/navigation/BreadCrumbs';
import { Box, SxProps, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useGetCurrentContract } from '../../hooks/useGetCurrentContract';
import { formatDate } from '@app/utils';
import { ContractStatusIndicator, LoadingSkeleton } from '@common/components';
import { ContractStatus } from '@app/types';
import { generateContractRowData, contractColumns } from './data';
import { AccountManagerRoutes } from '@app/types/navigation';
import { ContractPdf } from '../contractPdf';
import { updateContractWithFiles } from 'src/accountManager/contracts/utlis/updateContract';

const contractItemGridStyle: SxProps = {
  display: 'grid',
  gridTemplateColumns: '1fr 2.7fr 2fr 2fr 1.5fr 1.5fr 1.5fr',
};

export const ContractHeader = () => {
  const { contractId } = useParams();
  const { contract, isLoading } = useGetCurrentContract(contractId);
  const contractRowData = generateContractRowData(contract);

  if (!contract) {
    return null;
  }

  const breadcrumbs = [
    {
      name: 'Contracts',
      linkTo: `/${AccountManagerRoutes.ACCOUNT_MANAGER}/${AccountManagerRoutes.CONTRACTS_DASHBOARD}`,
    },
    { name: contract?.seller_contract_number },
  ];

  return (
    <Box>
      <ScrapexBreadcrumbs breadcrumbs={breadcrumbs} />
      <Box
        sx={{
          height: '100%',
          backgroundColor: 'var(--white-color)',
          borderRadius: '8px',
          mt: '20px',
        }}
      >
        {isLoading ? (
          <LoadingSkeleton />
        ) : (
          <>
            <Box
              sx={{
                p: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <Typography variant="h6">
                    Contract No.{contract?.seller_contract_number}
                  </Typography>
                  <ContractStatusIndicator
                    status={contract?.deal_status || ContractStatus.Canceled}
                  />
                </Box>
                <Typography sx={{ color: 'var(--other-500)', mt: '8px' }}>
                  <h6>Contract Date {contract ? formatDate(contract.contract_date) : ''}</h6>
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <ContractPdf
                  role="seller"
                  fileId={contract.seller_contract}
                  contract={contract}
                  onUpdateContractWithFiles={(data) => {
                    updateContractWithFiles({
                      contractId: contract.id,
                      contractType: 'seller',
                      ...data,
                    });
                  }}
                />
                <ContractPdf
                  role="buyer"
                  fileId={contract.buyer_contract}
                  contract={contract}
                  onUpdateContractWithFiles={(data) => {
                    updateContractWithFiles({
                      contractId: contract.id,
                      contractType: 'buyer',
                      ...data,
                    });
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                ...contractItemGridStyle,
              }}
            >
              {contractColumns.map((column) => (
                <Box
                  key={column}
                  sx={{
                    p: '10px 16px',
                    fontSize: '12px',
                    color: 'var(--other-500)',
                    backgroundColor: 'var(--other-50)',
                  }}
                >
                  {column}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                ...contractItemGridStyle,
                alignItems: 'center',
              }}
            >
              {contractRowData.map((item, index) => (
                <Box sx={{ p: '8px 16px' }} key={index}>
                  {item.value}
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

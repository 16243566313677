import { Box, IconButton, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-premium';

import {
  ScrapexBodyTextMedium,
  ScrapexGrayText,
  ShipmentStatusIndicator,
  TextWithSubtitle,
} from '@common/components';
import classes from '../styles.module.scss';

import { OpenInNewOutlined } from '@mui/icons-material';
import {
  formatDate,
  formatTime,
  renderDateOrNone,
  renderEstimatedOrFinalWeight,
  renderHedgingDetails,
  renderValueOrNone,
} from '@app/utils';
import { IHedge } from '@app/types';
import {
  currentLMESortComparator,
  dateSortComparator,
  hedgeSortComparator,
} from '@app/utils/sortComparators';
import { CurrentLMEHeader, operator } from 'src/hedgeAdmin/hedging/shared';
interface IGenereateShipmentColumns {
  onHedgeCreateDrawer: (shipmentId: string, companyId: string) => void;
  onHedgeRequestDrawer: (hedge: IHedge) => void;
}

export const generateUnhedgedShipmentColumns = ({
  onHedgeCreateDrawer,
  onHedgeRequestDrawer,
}: IGenereateShipmentColumns) => {
  const columns: GridColDef[] = [
    {
      field: 'contract_number',
      headerName: 'Contract No.',
      width: 140,
      headerClassName: classes.commonHeader,
      renderCell: (params) => (
        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
          {renderValueOrNone(params.value)}
        </Typography>
      ),
    },
    {
      field: 'shipment_number',
      headerName: 'Shipment No.',
      width: 140,
      headerClassName: classes.commonHeader,
      renderCell: (params) => (
        <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
          {renderValueOrNone(params.value)}
        </Typography>
      ),
    },
    {
      field: 'shipment_status',
      headerName: 'Status',
      width: 160,
      headerClassName: classes.commonHeader,
      renderCell: (params) => <ShipmentStatusIndicator status={params.value} />,
    },

    {
      field: 'estimated_weight',
      headerName: 'Final Weight, mt',
      width: 120,
      headerClassName: classes.commonHeader,
      renderCell: (params) =>
        renderEstimatedOrFinalWeight(params.row.final_weight, params.row.estimated_weight),
    },
    {
      field: 'ordinal_number',
      headerName: 'Ordinal No.',
      width: 96,
      headerClassName: classes.commonHeader,
    },
    {
      field: 'product',
      headerName: 'Metal/Product',
      width: 200,
      headerClassName: classes.commonHeader,
      renderCell: (params) => <TextWithSubtitle title={params.row.metal} subtitle={params.value} />,
    },
    {
      field: 'formula',
      headerName: 'Formula',
      width: 200,
      cellClassName: classes.border,
      headerClassName: classes.commonHeader,
    },
    {
      field: 'seller',
      headerName: 'Seller Name',
      width: 200,
      valueGetter: (params) => params.row.seller_name,

      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <ScrapexBodyTextMedium>{params.row.seller_name}</ScrapexBodyTextMedium>
          <ScrapexGrayText>{params.row.seller_email}</ScrapexGrayText>
        </Box>
      ),
      headerClassName: classes.sellerHeader,
    },
    {
      field: 'seller_hedge',
      headerName: 'Hedging',
      width: 200,
      headerClassName: classes.sellerHeader,
      valueGetter: (params) => params?.row?.seller_hedge?.hedge_number,
      sortComparator: hedgeSortComparator,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            width: '200px',
            gap: '4px',
          }}
        >
          <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
            {renderHedgingDetails(params.row.seller_hedge)}
          </Typography>
          <IconButton
            onClick={() =>
              params.value
                ? onHedgeRequestDrawer(params.row.seller_hedge)
                : onHedgeCreateDrawer(params.row.id, params.row.seller_id)
            }
          >
            <OpenInNewOutlined fontSize="small" color="secondary" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'seller_request_time',
      headerName: 'Date/Time',
      width: 200,
      headerClassName: classes.sellerHeader,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },

      renderCell: (params) =>
        params.value ? (
          <TextWithSubtitle
            title={params.value}
            subtitle={formatTime(params.row.seller_request_time)}
          />
        ) : (
          <ScrapexGrayText>None</ScrapexGrayText>
        ),
      sortComparator: dateSortComparator,
    },
    {
      field: 'seller_pure_metal',
      headerName: 'Pure metal, mt',
      width: 200,
      headerClassName: classes.sellerHeader,
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'seller_quotation',
      headerName: 'Current LME Request',
      width: 230,
      headerClassName: classes.sellerHeader,
      renderHeader: (params) => <CurrentLMEHeader {...params} />,
      valueGetter: (params) => {
        const {
          seller_tp_exchange_level: tp_exchange_level,
          seller_olme_date: olme_date,
          seller_quotation: quotation,
        } = params.row;
        if (olme_date) {
          return formatDate(olme_date);
        } else {
          return tp_exchange_level || quotation || null;
        }
      },
      filterOperators: [operator],
      renderCell: (params) => {
        const { seller_tp_exchange_level: tp_exchange_level } = params.row;

        return (
          <span>
            {renderValueOrNone(params.value)}
            {tp_exchange_level && <ScrapexGrayText> TP</ScrapexGrayText>}
          </span>
        );
      },
      sortComparator: currentLMESortComparator,
    },
    {
      field: 'seller_fixed_exchange',
      headerName: 'Fix Exchange',
      width: 200,
      headerClassName: classes.sellerHeader,
      renderCell: (params) => renderValueOrNone(params.value),
    },

    {
      field: 'seller_limit_fixation_date',
      headerName: 'Limit Fix.Date',
      width: 140,
      headerClassName: classes.sellerHeader,
      cellClassName: classes.border,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => {
        return renderDateOrNone(params.row.seller_limit_fixation_date);
      },
      sortComparator: dateSortComparator,
    },

    {
      field: 'buyer',
      headerName: 'Buyer Name',
      width: 200,
      valueGetter: (params) => params.row.buyer_name,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <ScrapexBodyTextMedium>{params.row.buyer_name}</ScrapexBodyTextMedium>
          <ScrapexGrayText>{params.row.buyer_email}</ScrapexGrayText>
        </Box>
      ),
      headerClassName: classes.buyerHeader,
    },
    {
      field: 'buyer_hedge',
      headerName: 'Hedging',
      width: 200,
      headerClassName: classes.buyerHeader,
      valueGetter: (params) => params?.row?.buyer_hedge?.hedge_number,
      sortComparator: hedgeSortComparator,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            width: '200px',
            gap: '4px',
          }}
        >
          <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}>
            {renderHedgingDetails(params.row.buyer_hedge)}
          </Typography>
          <IconButton
            onClick={() =>
              params.value
                ? onHedgeRequestDrawer(params.row.buyer_hedge)
                : onHedgeCreateDrawer(params.row.id, params.row.buyer_id)
            }
          >
            <OpenInNewOutlined fontSize="small" color="secondary" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'buyer_request_time',
      headerName: 'Date/Time',
      width: 200,
      headerClassName: classes.buyerHeader,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },

      renderCell: (params) =>
        params.value ? (
          <TextWithSubtitle
            title={params.value}
            subtitle={formatTime(params.row.buyer_request_time)}
          />
        ) : (
          <ScrapexGrayText>None</ScrapexGrayText>
        ),
      sortComparator: dateSortComparator,
    },
    {
      field: 'buyer_pure_metal',
      headerName: 'Pure metal, mt',
      width: 200,
      headerClassName: classes.buyerHeader,
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'buyer_quotation',
      headerName: 'Current LME Request',
      width: 230,
      headerClassName: classes.buyerHeader,
      renderHeader: (params) => <CurrentLMEHeader {...params} />,
      valueGetter: (params) => {
        const {
          buyer_tp_exchange_level: tp_exchange_level,
          buyer_olme_date: olme_date,
          buyer_quotation: quotation,
        } = params.row;
        if (olme_date) {
          return formatDate(olme_date);
        } else {
          return tp_exchange_level || quotation || null;
        }
      },
      filterOperators: [operator],
      renderCell: (params) => {
        const { buyer_tp_exchange_level: tp_exchange_level } = params.row;

        return (
          <span>
            {renderValueOrNone(params.value)}
            {tp_exchange_level && <ScrapexGrayText> TP</ScrapexGrayText>}
          </span>
        );
      },
      sortComparator: currentLMESortComparator,
    },
    {
      field: 'buyer_fixed_exchange',
      headerName: 'Fix Exchange',
      width: 180,
      headerClassName: classes.buyerHeader,
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'buyer_limit_fixation_date',
      headerName: 'Limit Fixation Date',
      width: 180,
      headerClassName: classes.buyerHeader,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => {
        return renderDateOrNone(params.row.buyer_limit_fixation_date);
      },
      sortComparator: dateSortComparator,
    },
  ];

  return { columns };
};

import { Dayjs } from 'dayjs';

export interface IContract {
  id: string;
  deal_status: ContractStatus;
  contract_date: string;
  product: string;
  metal: string;
  formula: string;
  hedged_shipments: number;
  product_weight: number;
  total_shipments: number;
  shipments_in_orders: number;
}

export interface IContractParty {
  email?: string;
  id: string;
  invitation_date?: string;
  name?: string;
  registration_date?: string;
  status?: string;
}

export interface IContractAdmin extends IContract {
  seller: IContractParty;
  seller_contract: string;
  seller_contract_number: string;
  buyer: IContractParty;
  buyer_contract: string;
  buyer_contract_number: string;
  completed_shipments: number;
  shipments_delivery_in_progress: number;
}

export interface ICreateContract {
  deal_status: ContractStatus;
  product: string;
  metal: string;
  formula: string;
  product_weight: string | number;
  total_shipments: number;
  contract_date: null | Dayjs;
  seller: string;
  seller_contract: string;
  seller_contract_number: string;
  buyer: string;
  buyer_contract: string;
  buyer_contract_number: string;
}

export interface IUpdateContract {
  buyer_contract: string;
  seller_contract: string;
}

export interface IContractUser extends IContract {
  contract: string;
  contract_number: string;
  customer_role: string;
  customer_id: string;
  completed_shipments: number;
  hedged_shipments: number;
  shipments_delivery_in_progress: number;
  shipments_in_orders: number;
  total_shipments: number;
  request_shipments: number;
}

export enum ContractStatus {
  Active = 'active',
  Filled = 'filled',
  Canceled = 'canceled',
}

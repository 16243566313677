export const dateSortComparator = (a: string, b: string) => {
  const dateA = new Date(a).getTime();
  const dateB = new Date(b).getTime();

  return dateA - dateB;
};

export const hedgeSortComparator = (a: string, b: string) => {
  if (a === null || a === undefined) return 1;
  if (b === null || b === undefined) return -1;

  const splitHedgeNumber = (hedge: string) => {
    const match = hedge.match(/^(\d+)([^\d]+)$/);
    if (!match) {
      return { numericPart: 0, nonNumericPart: hedge };
    }
    const numericPart = parseInt(match[1], 10);
    const nonNumericPart = match[2];
    return { numericPart, nonNumericPart };
  };

  // Split hedge numbers into numeric and non-numeric parts
  const { numericPart: numA, nonNumericPart: suffixA } = splitHedgeNumber(a);
  const { numericPart: numB, nonNumericPart: suffixB } = splitHedgeNumber(b);

  // Compare numeric parts as integers
  if (numA !== numB) {
    return numA - numB;
  }

  // If numeric parts are equal, compare non-numeric parts (suffixes)
  return suffixA.localeCompare(suffixB, undefined, { numeric: true });
};

export const currentLMESortComparator = (a: string | number, b: string | number) => {
  if (a === null || a === undefined) return 1;
  if (b === null || b === undefined) return -1;

  const typeA = typeof a;
  const typeB = typeof b;

  // Handle different types
  if (typeA !== typeB) {
    // If types are different, prioritize strings(date) over numbers(values)
    return typeA === 'string' ? -1 : 1;
  }

  if (typeA === 'string') {
    return dateSortComparator(a as string, b as string);
  } else if (typeA === 'number') {
    return (a as number) - (b as number);
  }

  return 0;
};

const hedgeStatusOrder: { [key: string]: number } = {
  Confirmed: 1,
  Request: 2,
  Quotation: 3,
  Canceling: 4,
  'Time out': 5,
};

export const hedgeStatusSortComparator = (a: string, b: string) => {
  const sortOrderA = hedgeStatusOrder[a];
  const sortOrderB = hedgeStatusOrder[b];

  if (sortOrderA === undefined && sortOrderB === undefined) {
    return 0;
  }
  if (sortOrderA === undefined) {
    return 1;
  }
  if (sortOrderB === undefined) {
    return -1;
  }
  return sortOrderA - sortOrderB;
};

import { GridColDef } from '@mui/x-data-grid-premium';

import { HedgingStatusIndicator, hedgeStatusMap } from '@common/components/display/hedging/status';
import { formatDate, formatTime, renderDateOrNone, renderValueOrNone } from '@app/utils';
import { TextWithSubtitle } from '@common/components/display/text';

import { CircleComponent } from 'src/hedgeAdmin/hedging/shared/table/circleComponent';
import { ScrapexGrayText } from '@common/components';
import { Box, IconButton } from '@mui/material';
import { ChatBubbleOutlineOutlined } from '@mui/icons-material';
import { HedgingStatus, IHedge } from '@app/types';
import { currentLMESortComparator, dateSortComparator } from '@app/utils/sortComparators';
import { getUserRole } from 'src/hedgeAdmin/hedging/shared/utils';
import { CurrentLMEHeader, operator } from 'src/hedgeAdmin/hedging/shared';
interface IGenerateHedgeHistoryColumns {
  onHandleCommentIconClick: (event: React.MouseEvent, value: IHedge) => void;
}

export const generateHedgeHistoryColumns = ({
  onHandleCommentIconClick,
}: IGenerateHedgeHistoryColumns) => {
  const columns: GridColDef[] = [
    {
      field: 'hedge_status',
      headerName: 'Status',
      width: 160,
      valueGetter: (params) => {
        return hedgeStatusMap[params.value as HedgingStatus];
      },
      renderCell: (params) => <HedgingStatusIndicator status={params.row.hedge_status} />,
    },
    {
      field: 'hedge_type',
      headerName: 'Type',
      width: 116,
      renderCell: (params) => params.value.toUpperCase(),
    },
    {
      field: 'request_time',
      headerName: 'Date of creation',
      width: 140,
      valueGetter: (params) => {
        return formatDate(params.value);
      },
      renderCell: (params) => (
        <TextWithSubtitle title={params.value} subtitle={formatTime(params.row.request_time)} />
      ),
      sortComparator: dateSortComparator,
    },
    {
      field: 'filled_time',
      headerName: 'Execution date',
      width: 140,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) =>
        params.value ? (
          <TextWithSubtitle title={params.value} subtitle={formatTime(params.row.filled_time)} />
        ) : (
          <ScrapexGrayText>None</ScrapexGrayText>
        ),
      sortComparator: dateSortComparator,
    },
    {
      field: 'role',
      headerName: '',
      width: 68,
      align: 'center',
      valueGetter: (params) => getUserRole(params.row.contract_number),
      renderCell: (params) => <CircleComponent contractNumber={params.row.contract_number} />,
    },
    {
      field: 'company_name',
      headerName: 'Company',
      width: 148,
      valueGetter: (params) => `${params.row.company_name} ${params.row.hedge_number}`,
      renderCell: (params) => (
        <TextWithSubtitle title={params.row.company_name} subtitle={params.row.hedge_number} />
      ),
    },
    {
      field: 'product',
      headerName: 'Metal/Product',
      width: 148,
      renderCell: (params) => <TextWithSubtitle title={params.row.metal} subtitle={params.value} />,
    },
    {
      field: 'pure_metal',
      headerName: 'Pure Metal, mt',
      width: 148,
      renderCell: (params) => renderValueOrNone(params.value),
    },

    {
      field: 'formula',
      headerName: 'Formula',
      width: 200,
    },
    {
      field: 'quotation',
      headerName: 'Current LME Request',
      width: 230,
      renderHeader: (params) => <CurrentLMEHeader {...params} />,
      valueGetter: (params) => {
        const { tp_exchange_level, olme_date, quotation } = params.row;
        if (olme_date) {
          return formatDate(olme_date);
        } else {
          return tp_exchange_level || quotation || null;
        }
      },
      filterOperators: [operator],
      renderCell: (params) => {
        const { tp_exchange_level } = params.row;

        return (
          <span>
            {renderValueOrNone(params.value)}
            {tp_exchange_level && <ScrapexGrayText> TP</ScrapexGrayText>}
          </span>
        );
      },
      sortComparator: currentLMESortComparator,
    },
    {
      field: 'fixed_exchange',
      headerName: 'Fixed LME, USD/mt',
      width: 160,
      renderCell: (params) => renderValueOrNone(params.value),
    },
    {
      field: 'message',
      headerName: 'Comments',
      width: 140,
      renderCell: (params) => (
        <>
          {params.value ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={(event) => onHandleCommentIconClick(event, params.row)}
            >
              1
              <IconButton>
                <ChatBubbleOutlineOutlined fontSize="small" color="action" />
              </IconButton>
            </Box>
          ) : (
            <ScrapexGrayText>None</ScrapexGrayText>
          )}
        </>
      ),
    },
    {
      field: 'limit_fixation_date',
      headerName: 'Limit Fixation Date',
      align: 'right',
      width: 140,
      valueGetter: (params) => {
        return params.value ? formatDate(params.value) : null;
      },
      renderCell: (params) => renderDateOrNone(params.row.limit_fixation_date),
      sortComparator: dateSortComparator,
    },
  ];

  return { columns };
};
